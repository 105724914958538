<div class="content-wrapper map-main">
  <div class="container">
    <div
      class="wrap_first my-vaccination-container mb-md-5 mb-4"
      style="max-width: 100%"
    >
      <h1 class="page-heading mb-4">Vaccination clinics near me</h1>
      <div class="my-vaccine-card-wrapper bg-white pt-md-4">
        <div class="left-tabs">
          <ul class="list-unstyled left-nav border-0">
            <li *ngFor="let item of searchData">
              <ng-container
                *ngTemplateOutlet="placeTemplate; context: { item: item }"
              ></ng-container>
            </li>
          </ul>
        </div>

        <div class="right-container pt-0 px-0 px-md-3">
          <div class="mb-3 d-sm-flex">
            <input
              #autocompleteInput
              type="text"
              placeholder="Type location to find vaccine clinics"
              class="mr-3 form-control"
              [formControl]="searchControl"
            />
            <button
              class="mt-2 mt-sm-0 px-4 btn btn-commonbtn button-primary custom-btn mx-auto"
              (click)="getGooglePlacesData()"
            >
              Get Vaccine Clinics
            </button>
          </div>

          <google-map
            #gm
            height="600px"
            width="100%"
            [center]="center"
            [zoom]="zoom"
          >
            <map-marker [position]="center" />
            <ng-container *ngFor="let place of searchData">
              <map-marker
                #mapMarker="mapMarker"
                [position]="place.geometry.location"
                (mapMouseover)="onMarkerHover(place, mapMarker, gm)"
                (mapMouseout)="onMarkerHoverEnd()"
                (mapMousedown)="onMarkerClick(place)"
              />
              <map-info-window #infoWindow></map-info-window>
            </ng-container>
          </google-map>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white">Please Wait.</p>
</ngx-spinner>

<ng-template #placeTemplate let-item="item">
  <div class="items-map" [ngClass]="{'items-map--selected': item.place_id === selectedMarker?.place_id}">
    <a (click)="onMarkerClick(item)">
      <h4 class="mb-1" *ngIf="item.business_status === 'OPERATIONAL'">
        {{ item.name }}
      </h4>
      <span class="badge badge-secondary">
        <small
          *ngIf="
            item.business_status === 'OPERATIONAL' &&
            item.opening_hours?.open_now === false
          "
          class="newclass"
        >
          CLOSED
        </small>
      </span>
      <span class="badge badge-secondary">
        <small
          *ngIf="
            item.business_status === 'OPERATIONAL' &&
            item.opening_hours?.open_now === true
          "
          class="newclass2"
        >
          OPEN
        </small>
      </span>
      <span class="badge badge-secondary">
        <svg-icon
          *ngIf="item.business_status === 'OPERATIONAL'"
          class="cursor-pointer"
          src="assets/icons/direction.svg"
          [stretch]="true"
          (click)="goToMap(item.place_id, item.formatted_address)"
        >
        </svg-icon>
      </span>
      <ngb-rating
        *ngIf="item.business_status === 'OPERATIONAL'"
        class="checked"
        [max]="5"
        [readonly]="true"
        [(rate)]="item.rating"
      >
      </ngb-rating>
    </a>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <ng-container
    *ngTemplateOutlet="placeTemplate; context: { item: selectedMarker }"
  ></ng-container>
</ng-template>
