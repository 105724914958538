import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { ApisService } from 'src/app/shared/services/api.service';

export interface PlacesData {
  business_status: string;
  formatted_address: string;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  place_id: string;
  rating: number;
  reference: string;
  user_ratings_total: number;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  opening_hours: {
    open_now: boolean;
  };
  photos: {
    height: number;
    html_attributions: string[];
    photo_reference: string;
    width: number;
  }[];
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  types: string[];
}
@UntilDestroy()
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @ViewChild('infoTemplate') infoTemplate!: TemplateRef<any>;
  @ViewChild('autocompleteInput', { static: true })
  public autocompleteInput!: ElementRef;

  public searchData: PlacesData[] = [];
  public selectedMarker: PlacesData | null = null;
  public zoom: number = 4;
  public latitude: number = -36.817685;
  public longitude: number = 175.699196;
  public searchControl: FormControl<string | null> = new FormControl<string>(
    ''
  );

  private readonly searchText: string = 'travel+health&vaccinationclinic';
  private autocomplete!: google.maps.places.Autocomplete;

  constructor(
    private readonly changeDatectionRef: ChangeDetectorRef,
    private readonly apiService: ApisService,
    private readonly toastr: ToastrService,
    private readonly SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.setCurrentPosition();
    this.initializeGooglePlacesAutocomplete();
  }

  public get center(): google.maps.LatLngLiteral {
    return { lat: this.latitude, lng: this.longitude };
  }

  public goToMap(data: string, address: string): void {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${address}&query_place_id=${data}`,
      '_blank'
    );
  }

  public getGooglePlacesData(): void {
    this.SpinnerService.show();
    this.apiService
      .getGooglePlacesApiData(this.searchText, this.latitude, this.longitude)
      .pipe(untilDestroyed(this))
      .subscribe(({ responseData: { results } }) => {
        this.searchData = results;
        this.changeDatectionRef.markForCheck();
        this.SpinnerService.hide();
      });
  }

  public onMarkerHover(
    marker: PlacesData,
    mapMarker: MapMarker,
    gm: GoogleMap
  ): void {
    this.selectedMarker = marker;
    const content = this.renderTemplate(this.infoTemplate);

    this.infoWindow.infoWindow?.setContent(content);
    this.infoWindow.infoWindow?.open({
      anchor: mapMarker.marker,
      map: gm as unknown as google.maps.Map,
    });
  }

  public onMarkerHoverEnd(): void {
    this.infoWindow.close();
  }

  public onMarkerClick(marker: PlacesData): void {
    this.selectedMarker = marker;
    const {
      geometry: {
        location: { lat, lng },
      },
    } = marker;
    this.latitude = lat;
    this.longitude = lng;
    this.zoom = 15;
  }

  private initializeGooglePlacesAutocomplete(): void {
    const options: google.maps.places.AutocompleteOptions = {
      types: ['(cities)'],
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      options
    );

    this.autocomplete.addListener('place_changed', () => {
      const { geometry } = this.autocomplete.getPlace();

      if (!geometry?.location) return;

      this.latitude = geometry.location.lat();
      this.longitude = geometry.location.lng();
      this.zoom = 12;
    });
  }

  private setCurrentPosition(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }: GeolocationPosition) => {
          this.latitude = latitude;
          this.longitude = longitude;
          this.zoom = 12;

          this.changeDatectionRef.markForCheck();
        },
        ({ message }: GeolocationPositionError) => this.toastr.error(message)
      );
    }
  }

  private renderTemplate(template: TemplateRef<any>): HTMLElement {
    const view = template.createEmbeddedView({});
    view.detectChanges();

    const container = document.createElement('div');
    view.rootNodes.forEach((node) => container.appendChild(node));

    return container;
  }
}
